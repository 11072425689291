<template>
  <div :class="$style.page">
    <SubHeader :userId="$route.params.id" />

    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Редактировать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Имя пользователя" prop="firstName">
        <el-input v-model="form.firstName"></el-input>
      </el-form-item>

      <el-form-item label="Фамилия" prop="lastName">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>

      <el-form-item label="Почта" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>

      <el-form-item label="Дата рождения">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Последний вход на сайт">
        <el-date-picker
          v-model="form.lastSignedInAt"
          type="date"
          :class="$style.date"
          readonly
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <div :class="$style.deliveryAddresses">
      <p>Адреса доставки:</p>
      <el-button type="primary" @click="openDeliveryAddressModal(null)"
        >Создать адрес</el-button
      >
    </div>
    <el-table :data="form.deliveryAddresses" stripe>
      <el-table-column prop="city" label="Город" :width="200">
      </el-table-column>
      <el-table-column prop="street" label="Улица" :width="200">
      </el-table-column>
      <el-table-column
        label="Дополнительная информация"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <p>
            Подъезд: {{ scope.row.additionalInfo.entrance }}, этаж:
            {{ scope.row.additionalInfo.floor }}, квартира:
            {{ scope.row.additionalInfo.flat }}, домофон:
            {{ scope.row.additionalInfo.intercom }}, частный дом:
            {{ scope.row.additionalInfo.isDetachedHouse ? 'Да' : 'Нет' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="comment"
        label="Комментарий"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.comment }}</p>
        </template>
      </el-table-column>

      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            editModal
            @edit="openDeliveryAddressModal({ ...scope.row })"
            @delete="removeAddress(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeliveryAddressModal
      v-if="dialogVisible"
      :is-show="dialogVisible"
      :address="addressData"
      @close="handleClose"
      @save="saveDeliveryAddress"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications.js'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

import SubHeader from '@/components/atoms/SubHeader.vue'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import DeliveryAddressModal from '@/components/atoms/DeliveryAddressModal.vue'

export default {
  ADDWINE_ADMIN_ROUTES,
  components: {
    SubHeader,
    ActionButtons,
    DeliveryAddressModal,
  },
  mixins: [notifications],
  async created() {
    await this.getUser()
  },
  data() {
    return {
      dialogVisible: false,
      address: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthday: '',
        lastSignedInAt: '',
        deliveryAddresses: [],
      },
      formAddress: {
        entrance: 'Подъезд:',
        flat: 'Квартира:',
        floor: 'Этаж:',
        intercom: 'Домофон:',
        isDetachedHouse: 'Частный дом:',
        city: 'Город:',
        comment: 'Комментарий:',
        street: 'Улица:',
      },
      addressData: null,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        email: [
          {
            pattern: regexp.email,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.phone,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    async getUser() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } = await delivery.AccountsCore.UsersActions.getById(
        this.$route.params.id,
      )

      if (error) return
      this.form = { ...this.form, ...value }
      if (!value.deliveryAddresses) {
        this.form.deliveryAddresses = []
      }
      loading.close()
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = {
            ...this.form,
            phone: this.form.phone.replace(/[^+0-9]/g, ''),
          }
          const { error } = await delivery.AccountsCore.UsersActions.update(
            this.$route.params.id,
            data,
          )

          loading.close()

          if (error === 'Ошибка обновления пользователя') {
            this.showNotification(
              'Пользователем с таким именем или телефоном уже существует',
              'error',
            )
            return
          }

          if (error) {
            this.showNotification('Ошибка создания пользователя', 'error')
            return
          }

          this.$router.push(ADDWINE_ADMIN_ROUTES.USERS.LIST)
        }
      })
    },
    async removeAddress(address) {
      const isConfirm = confirm(`Вы точно хотите удалить адрес`)

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AccountsCore.UsersActions.deleteAddress(
        address.id,
      )

      loading.close()

      if (error) return

      this.getUser()
    },
    async updateAddress(address) {
      const isConfirm = confirm(`Вы точно хотите обновить адрес?`)

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AccountsCore.UsersActions.updateAddress(
        address.id,
        address,
      )

      loading.close()

      if (error) {
        this.showError('Ошибка редактирования адреса: ' + error)
        return
      }

      this.showSuccess('Адрес успешно отредактирован')

      this.dialogVisible = false
      this.getUser()
    },
    async createAddress(address) {
      const loading = this.$loading({
        lock: true,
      })

      const userId = this.$route.params.id

      const { error } = await delivery.AccountsCore.UsersActions.createAddress(
        userId,
        address,
      )

      loading.close()

      if (error) {
        this.showError('Ошибка создания адреса: ' + error)
        return
      }

      this.showSuccess('Адрес успешно создан')

      this.dialogVisible = false
      this.getUser()
    },
    openDeliveryAddressModal(address = null) {
      this.addressData = address
        ? address
        : {
            city: '',
            street: '',
            additionalInfo: {
              flat: '',
              entrance: '',
              intercom: '',
              floor: '',
            },
            comment: '',
            coordinates: {
              lat: null,
              lng: null,
            },
          }

      this.dialogVisible = true
    },
    handleClose(done) {
      this.addressData = null
      this.dialogVisible = false
      this.getUser()
      done()
    },
    saveDeliveryAddress(address) {
      address?.id ? this.updateAddress(address) : this.createAddress(address)
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .form {
    & > div > label {
      text-align: left;
    }

    .date {
      width: 100%;
    }
  }
  .deliveryAddresses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-weight: bold;
  }
}
</style>
